(function ($) {
    // Event Listener for Contact Form 7 Forms
    document.addEventListener('wpcf7mailsent', function (event) {
        Cookies.set('post_type_newsletter_subscribed', 1, {
            expires: 365
        });
        setTimeout(function() {
            $('.post-type-popup').fadeOut();
            popupBlurOut();
        }, 3000);
    }, false);


    function popupBlurOut() {
        var popupAnimationDuration = 3000;
        $('body > :not(.post-type-popup)').removeClass('popup-blur');
        $('body > :not(.post-type-popup)').addClass('popup-blur-out');
        setTimeout(function() {
            $('body > :not(.post-type-popup)').removeClass('popup-blur-out');
        }, popupAnimationDuration);
    }

    $(document).ready(function () {
        // Initialize popups
        var popups = [];
        $('.post-type-popup').each(function () {
            popups.push(new Popup(this));
        });

        // Sort popups
        popups.sort(function (a, b) {
            return b.priority - a.priority;
        });

        // Display popups
        for (var i = 0; i < popups.length; i++) {
            var popup = popups[i];
            if (!popup.hasBeenViewed()) {
                popup.display();
                break;
            }
        }

        function Popup(elem) {
            var self = this;

            self.id = $(elem).data('id');
            self.priority = $(elem).data('priority') ? parseInt($(elem).data('priority')) : 0;
            self.expiration = $(elem).data('expiration') ? parseInt($(elem).data('expiration')) : 0;
            self.delay = $(elem).data('delay') ? parseInt($(elem).data('delay')) : 0;
            self.debug = $(elem).data('debug') ? parseInt($(elem).data('debug')) : 0;
            self.cookieId = 'post_type_popup_' + self.id;

            self.display = display;
            self.hasBeenViewed = hasBeenViewed;

            // Close popup event listeners
            $('.popup-close').click(close);
            $('.popup-overlay').click(close);

            /**/
            function display() {
                setTimeout(function () {
                    $(elem).fadeIn();
                    $('body > :not(.post-type-popup)').addClass('popup-blur');

                    if (!self.debug) {
                        Cookies.set(self.cookieId, 1, {expires: self.expiration});
                    }
                }, self.delay);
            }

            function hasBeenViewed() {
                var popupCookieSet = (Cookies.get(self.cookieId) !== undefined) || Cookies.get('post_type_newsletter_subscribed');
                return popupCookieSet && !self.debug;
            }



            function close() {
                $(elem).fadeOut();
                popupBlurOut();
            }
        }
    });
}(jQuery));